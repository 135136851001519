/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.flatpickr-months .flatpickr-month {background-color: #8A36FF !important }
.flatpickr-current-month .flatpickr-monthDropdown-months{background-color: #8A36FF !important}
span.flatpickr-weekday {background-color: #8A36FF !important;color:#ffffff!important}
.flatpickr-weekdays{background-color: #8A36FF !important;}
.flatpickr-day.selected{
  background-color: #8A36FF !important;
  border:#8A36FF
}
